<template>
  <div class="wechat-reading">
    <div class="banner">
      <img src="https://img.xinshu.me/upload/8301476f4e484d5da4a3a742e2401ac6" alt="">
      <div class="time">第一期 <span style="font-size: 1rem;">6.23 - 7.14</span></div>
    </div>
    <div class="content">
      <div class="content-card">
        <div v-for="item in bookList" :key="item.name">
          <div class="item" @click="readBook(item.ebook, item.name)">
            <div class="cover">
              <img :src="item.cover" alt="">
            </div>
            <div class="info">
              <div class="line-1">
                <div class="name">{{item.name}}</div>
                <div class="tag">{{item.tag}}</div>
              </div>
              <div class="line-2">
                {{item.desc}}
              </div>
              <div class="line-3">
                <div class="review">{{item.review}}
                  <div class="left" :style="{'background-color': item.tagColor}"></div>
                  <div class="tag2" :style="{color: item.tagColor}">{{item.tag2}}</div>
                  <div class="right" :style="{'background-color': item.tagColor}"></div>
                </div>
                <div class="listen" @click.stop="listenBook(item.audiobook, item.name)">听有声书</div>
              </div>
            </div>
          </div>
          <hr class="item-hr">
        </div>
        <div class="intro">
          <div class="ti">邀请好友</div>
          <div class="ti">得B5锁线精装照片书5折券</div>
          <div class="video">
            <video-player
              class="4:3"
              src="https://img.xinshu.me/upload/860f0def754d45328e22f50386cdf032"
              poster="https://img.xinshu.me/upload/444c052763dd49d8905a3884ae032e3b"
            />
          </div>
          <div class="ti">参与步骤</div>
          <div class="join-step">
            <img src="https://img.xinshu.me/upload/4df09d23f71c400b9e662709d211ac8e" alt="">
          </div>
          <div class="share-box">
            <span class="share-btn" @click="share">我要分享给好友</span>
          </div>
        </div>
      </div>
    </div>
    <popup v-if="posterPop" guide="保存图片并分享给好友" :src="poster" @close="posterPop = false"/>
    <popup v-if="gzhPop" @close="gzhPop = false">
      <b-card class="mb-0">
        <b-card-title>关注公众号</b-card-title>
        <square src="https://img.xinshu.me/upload/182a749ed6ba491d9341fb4bfed09859" style="max-width: 200px;" class="mx-auto"/>
        <div class="mt-3">
          长按识别上方二维码，领取优惠券
        </div>
      </b-card>
    </popup>
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer'

export default {
  name: 'wechatReading',
  title: '心书读书分享会',
  components: { VideoPlayer },
  data() {
    return {
      bookList: [],
      posterPop: false,
      gzhPop: false,
      qrTicket: ''
    }
  },
  computed: {
    poster() {
      const qrcode = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + encodeURIComponent(this.qrTicket)
      return 'https://canvas.xinshu.me/generate/dushuhui' + this.serialize({
        avatar: this.user.avatar,
        nickname: this.user.nickname,
        qrcode: qrcode
      })
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.fetchBooks()
      this.fetchProfile()
    },
    fetchBooks() {
      return this.$req.get('/buckets/activity:reading').then(result => {
        this.bookList = result
      })
    },
    readBook(url, name) {
      this.$gtag.event('wechatreading_read', {
        event_label: name
      })
      window.open(url)
    },
    listenBook(url, name) {
      this.$gtag.event('wechatreading_listen', {
        event_label: name
      })
      window.open(url)
    },
    async share() {
      this.user.subscribe ? this.posterPop = true : this.gzhPop = true
      await this.$req.post('/api/activity/readbooks')
    },
    fetchProfile() {
      return this.$ajax.fetchUserProfile().then(result => {
        this.qrTicket = result.qrTicket
      })
    }
  }
}
</script>

<style lang="scss">
body[data-page="wechatReading"] {
  main {
    padding-bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
$theme: #72A8BC;
$theme-light: #BFB392;

.wechat-reading {
  max-width: 480px;
  min-height: 100vh;
  padding: 0;
  background-color: $theme;

  .color-theme {
    color: $theme;
  }

  .banner {
    position: relative;
    .time {
      position: absolute;
      top: 30%;
      left: 6.4%;
      font-size: 1.2rem;
      color: #fff;
      font-weight: 300;
    }
  }

  .content {
    padding: 1rem;

    .content-card {
      padding: 1.2rem;
      border-radius: 8px;
      background-color: #fff;

      .item-hr {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
      }

      .item {
        display: flex;
        cursor: pointer;
        .cover {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          img {
            border-radius: 4px;
          }
        }
        .info {
          flex: 3;
          padding-left: 1rem;
          font-size: 0.8rem;
          color: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .line-1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
              color: #000;
              font-size: 1.2rem;
            }
            .tag {
              color: rgba(0, 0, 0, 0.3);
            }
          }
          .line-2 {
            margin: 0.5rem 0;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -moz-box-orient: vertical;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
          .line-3 {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .review {
              display: flex;
              align-items: center;
              .left {
                margin-left: 1rem;
                display: inline-block;
                -webkit-mask-image: url(https://img.xinshu.me/upload/6ad44f0104e846f388ce1cd084128c30);
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: contain;
                width: 0.5rem;
                height: 1rem;
              }
              .right {
                display: inline-block;
                -webkit-mask-image: url(https://img.xinshu.me/upload/2e46878f478a46a4947259ac91ce54cb);
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: contain;
                width: 0.5rem;
                height: 1rem;
              }
              .tag2 {
                display: inline-block;
              }
            }
            .listen {
              color: #fff;
              background-color: $theme-light;
              border-radius: 50rem;
              padding: 0.22rem 0.5rem;
            }
          }
        }
      }

      .intro {
        .ti {
          font-size: 1.3rem;
          color: $theme-light;
        }
        .video {
          margin: 1rem 0 1.5rem 0;
          ::v-deep .video-player {
            border-radius: 6px;
          }
          ::v-deep video {
            border-radius: 6px;
          }
        }
        .join-step {
          padding: 1.2rem 0.5rem;
        }
        .share-box {
          text-align: center;
          margin: 1.6rem 0;
          .share-btn {
            background-color: $theme-light;
            color: #ffff;
            border-radius: 50rem;
            padding: 0.8rem 1.2rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
